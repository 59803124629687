<template>
    <div>
        <el-collapse>
            <el-collapse-item>
                <template slot="title">
                    <i class="header-icon el-icon-s-operation"></i>筛选
                    <span class="totalwrp">总条数:{{ total }}</span>
                </template>
                <div class="dataFilterWrap">
                    <el-row :gutter="8">
                        <el-col :span="4">
                            <el-button type="primary" @click="beforeAdd">添加</el-button>
                        </el-col>
                        <!-- <el-col :span="8">
                            <label>状态</label>
                            <el-select v-model="isdelete" placeholder="请选择">
                                <el-option value="" label="所有"></el-option>
                                <el-option :value="0" label="正常"></el-option>
                                <el-option :value="1" label="已删除"></el-option>
                            </el-select>
                        </el-col> -->
                        <el-col :span="8">
                            <el-input type="text" v-model="keyword" placeholder="输入分支关键字"></el-input>
                            <el-button type="success" round icon="el-icon-search" @click="searchKeyWord">筛选</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-collapse-item>
        </el-collapse>
        <el-table :data="agencyList" stripe fit tooltip-effect="dark">
            <el-table-column label="序号" width="100">
                <template slot-scope="scope">{{ scope.$index }}</template>
            </el-table-column>
            <el-table-column label="账号" width="120" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.TravelArea_Account }}</template>
            </el-table-column>
            <el-table-column label="分支名称" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.TravelArea_Name }}</template>
            </el-table-column>
            <el-table-column label="操作" width="300" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" plain @click="edtPolicyAct(scope.row)">修改</el-button>
                    <el-popconfirm  confirm-button-text="好的"
                        cancel-button-text="再考虑" icon="el-icon-info" icon-color="red" title="确定要删除吗？"
                        @confirm="sendDelete(scope.row)">
                        <el-button type="danger" size="mini" plain slot="reference">删 除</el-button>
                    </el-popconfirm>    
                    <el-popconfirm confirm-button-text="好的" cancel-button-text="再考虑" icon="el-icon-info" icon-color="red"
                        title="确定要重置密码吗？" @confirm="sendReset(scope.row)">
                        <el-button type="default" size="mini" plain slot="reference">重置密码</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next, jumper" :total="total" @current-change="setPageIndx">
        </el-pagination>

        <el-dialog :visible.sync="addPolicy" title="添加分支" width="77%" modal :close-on-click-modal="false">
            <div class="details-wrp">
                <el-row class="policyWrp">
                    <el-col :span="12">
                        <label>分支名称</label>
                        <el-input type="text" v-model="current.TravelArea_Name"></el-input>
                    </el-col>
                    <el-col :span="12">
                        <label>登陆账号（密码默认123456）</label>
                        <el-input type="text" v-model="current.TravelArea_Account"></el-input>
                    </el-col>
                </el-row>
                <div class="btm-btns">
                    <el-button plain round type="default" @click="cancelAdd">取消</el-button>
                    <el-button plain round type="primary" @click="sureAdd">确定</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="edtPolicy" title="修改分支" width="77%" modal :close-on-click-modal="false">
            <div class="details-wrp">
                <el-row class="policyWrp">
                    <el-col :span="12">
                        <label>分支名称</label>
                        <el-input type="text" v-model="current.TravelArea_Name"></el-input>
                    </el-col>
                    <el-col :span="12">
                        <label>登陆账号</label>
                        <el-input type="text" v-model="current.TravelArea_Account"></el-input>
                    </el-col>
                </el-row>
                <div class="btm-btns">
                    <el-button plain round type="default" @click="cancelEdt">取消</el-button>
                    <el-button plain round type="primary" @click="sureEdt">确定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            agencyList: [],
            areaList:[],
            current: {
                TravelArea_Account: "",
                TravelArea_Name: "",
            },
            productsCheckList: [],
            products: "",
            isdelete: "",
            addPolicy: false,
            edtPolicy: false,
            keyword: "",
            pageindex: 1,
            pagesize: 10,
            total: 0
        }
    },

    mounted() {
        this.pullList()
    },
    methods: {
        cancelEdt() {
            this.edtPolicy = false
        },
        cancelAdd() {
            this.addPolicy = false
        },
        checkGrpChange(e) {
            console.log(e)
        },
        edtPolicyAct(itm) {
            this.current = { ...itm }
            this.edtPolicy = true
        },

        sureEdt() {

            this.ipost(
                "/AdminTravel/TravelArea.ashx?type=update", {
                model: JSON.stringify(this.current)
            },
                (res) => {
                    console.log("修改成功 res:", res)
                    this.edtPolicy = false
                    this.pullList()
                }
            );
        },
        beforeAdd() {
            this.resetCurrent()
            this.addPolicy = true
        },
        resetCurrent() {
            this.current.TravelArea_Account = "";
            this.current.TravelArea_Name = "";

        },
        sureAdd() {
            console.log("current add:", this.current)
            
            this.ipost(
                "/AdminTravel/TravelArea.ashx?type=insert", {
                model: JSON.stringify(this.current)
            },
                (res) => {
                    // console.log("添加成功 res:", res)
                    this.addPolicy = false
                    this.pullList()
                }
            );
        },
        sendReset(itm) {
            itm.TravelAgency_Password = '49ba59abbe56e057'
            this.ipost(
                "/AdminTravel/TravelArea.ashx?type=update", {
                model: JSON.stringify(itm)
            },
                (res) => {
                    // console.log("添加成功 res:", res)
                    this.pullList()
                    this.$message("密码重置成功!")
                }
            );
        },
        sendDelete(itm) {
            this.ipost(
                "/AdminTravel/TravelArea.ashx?type=delete", {
                id: itm.TravelArea_ID,
            },
                (res) => {
                    this.pullList()
                }
            );
        },
        pullList() {
            this.ipost(
                "/AdminTravel/TravelArea.ashx?type=select", {
                keyword: this.keyword,
                isdelete: this.isdelete,
                pageindex: this.pageindex,
                pagesize: this.pagesize,
            },
                (res) => {
                    console.log("res:", res)
                    this.agencyList = res.list
                    this.total = res.count
                }
            );
        },
        setPageIndx(pgindx) {
            this.pageindex = pgindx;
            this.pullList();
        },
        searchKeyWord() {
            this.pageindex = 1;
            this.pullList();
        },
    }
}
</script>

<style scoped>
.el-table table {
    width: 100%;
}

.cell button,
.dialog-footer .el-button {
    margin: 0 8px;
}

.search-wrp .el-input {
    width: 60%;
    margin-right: 10px;
}

.el-collapse-item .el-row {
    padding: 6px 12px;
}

.el-pagination {
    margin: 16px auto;
    text-align: center;
}

.el-dialog .el-select {
    width: 100%;
}

.btm-btns {
    margin-top: 25px;
    text-align: center;
}

.el-col {
    padding: 5px;
}

.el-col label {
    line-height: 2.5;
}

.dataFilterWrap .el-row .el-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.dataFilterWrap .el-row .el-col label {
    display: block;
    width: 80px;
    padding-right: 2px;
}

.dataFilterWrap button {
    margin-left: 10px;
}

.search-wrp-top .el-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-details .el-input,
.order-details .el-select {
    width: 90%;
}

.order-details button {
    display: block;
    height: 40px;
    width: 90%;
}

.order-details label {
    display: block;
    line-height: 3;
}

.details-btn {
    text-align: center;
    padding-top: 20px;
}
</style>
